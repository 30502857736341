<template>
  <Suspense>
    <template #default>
      <Transactions />
    </template>
    <template #fallback>
      Loading...
    </template>
  </Suspense>
</template>

<script>
import Transactions from '@/main/Transactions.vue';

export default {
    components: {
        Transactions,
    },
};
</script>
