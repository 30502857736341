<template>
  <div class="table-container">
    <AddEmail @email-added="emailAdded" />

    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>E-mail</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="email in emails"
          :key="email"
        >
          <td>{{ email }}</td>
          <td>
            <DeleteEmail
              :item="email"
              @item-deleted="emailDeleted"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import AddEmail from './AddEmail.vue';
import DeleteEmail from './DeleteEmail.vue';

export default {
    components: {
        AddEmail,
        DeleteEmail,
    },

    async setup() {
        const emails = ref([]);

        const {
            getEmails,
        } = inject('emails');

        emails.value = await getEmails();

        const emailAdded = (addedEmail) => {
            emails.value = [...emails.value, addedEmail];
        };

        const emailDeleted = (deletedEmail) => {
            emails.value = emails.value.filter((email) => email !== deletedEmail);
        };

        return {
            emails,
            emailAdded,
            emailDeleted,
        };
    },
};
</script>
