<template>
  <div class="my-3">
    <button
      class="button is-small is-primary"
      title="Edit Bank"
      @click="openModal"
    >
      Edit Bank
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">Bank Name</label>
              <div class="control">
                <input
                  v-model="bankToEdit.name"
                  class="input"
                  type="text"
                  placeholder="Enter bank name"
                >
              </div>
            </div>

            <div class="field is-full column">
              <label class="label">Bank Code</label>
              <div class="control">
                <input
                  v-model="bankToEdit.code"
                  class="input"
                  :class="{ 'is-danger': errors.bankCode }"
                  type="text"
                  placeholder="Enter bank code"
                >

                <p
                  v-if="errors.bankCode"
                  class="help is-danger"
                >
                  {{ errors.bankCode }}
                </p>
              </div>
            </div>

            <div class="field is-full column">
              <label class="checkbox">
                <input
                  v-model="bankToEdit.maintanance"
                  type="checkbox"
                >
                Under Maintanance
              </label>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="bankEdition"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    props: {
        bank: {
            type: Object,
            default: null,
        },
    },
    emits: ['bankEdited'],

    async setup(props, { emit }) {
        const {
            editBank,
        } = inject('banks');

        const bankToEdit = ref({
            bankId: props.bank.bankId,
            name: props.bank.name,
            code: props.bank.code,
            maintanance: props.bank.maintanance,
        });
        const isLoading = ref(false);
        const isModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            isModalOpen.value = false;
        };

        const openModal = () => {
            isModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = ['name', 'code'];
            return !requiredFields.every((keyName) => (
                bankToEdit.value[keyName]
                && bankToEdit.value[keyName].trim()
            ));
        };

        const validate = () => {
            if (!/^\d+$/.test(bankToEdit.value.code)) {
                errors.value.bankCode = 'Bank Code should contain only digits.';
                return false;
            }

            if (bankToEdit.value.code.length !== 4) {
                errors.value.bankCode = 'Bank Code should have four digits.';
                return false;
            }

            return true;
        };

        const bankEdition = async () => {
            if (validate()) {
                const editedBank = await editBank(bankToEdit.value);
                emit('bankEdited', editedBank);
                closeModal();
            }
        };

        return {
            bankEdition,
            bankToEdit,
            closeModal,
            errors,
            isLoading,
            isModalOpen,
            isSaveButtonActive,
            openModal,
        };
    },
};
</script>
