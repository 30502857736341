<template>
  <tr>
    <td>
      <p>
        {{ ip }}
      </p>
    </td>
    <td class="has-text-right">
      <button
        class="button is-small is-success"
        title="Unlock IP address"
        @click="unblockIp(ip)"
      >
        <span class="icon">
          <i class="fas fa-lock-open" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { inject } from 'vue';

export default {
    props: {
        ip: {
            type: String,
            required: true,
        },
    },

    setup() {
        const { unblockIp } = inject('blocked_ips');
        return { unblockIp };
    },
};
</script>
