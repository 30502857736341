import setupFilters from './filters';
import setupSorting from './sorting';
import setupTransactions from './transactions';

export default () => {
    const filters = setupFilters();
    const sorting = setupSorting();
    const transactions = setupTransactions(filters, sorting.orderingList);

    return {
        filters,
        sorting,
        transactions,
    };
};
