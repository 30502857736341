import { httpGet, httpPost } from '../http';

export default () => {
    const getEmails = async () => {
        const response = await httpGet('blocked-emails/');
        return response;
    };

    const camelToSnake = (data) => ({
        email: data.email,
    });

    const postEmail = async (data) => (
        httpPost('blocked-emails/', {}, camelToSnake(data))
    );

    const deleteEmail = async (data) => (
        httpPost('blocked-emails/delete/', {}, camelToSnake(data))
    );

    return {
        getEmails,
        postEmail,
        deleteEmail,
    };
};
