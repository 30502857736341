import { jws } from 'jsrsasign';
import jwkToPem from 'jwk-to-pem';

const tokenEndpointUrl = `${process.env.VUE_APP_COGNITO_DOMAIN_URL}/oauth2/token`;

const getJwkCognitoKeys = async () => {
    const {
        VUE_APP_COGNITO_IDP_URL,
        VUE_APP_COGNITO_USERPOOL_ID,
    } = process.env;

    const jwkEndpointUrl = `${VUE_APP_COGNITO_IDP_URL}/${VUE_APP_COGNITO_USERPOOL_ID}/.well-known/jwks.json`;

    const response = await fetch(jwkEndpointUrl);

    const { keys } = await response.json();

    return keys;
};

const checkAccessTokenValidity = async (accessToken) => {
    const jwkCognitoKeys = await getJwkCognitoKeys();

    const { headerObj } = jws.JWS.parse(accessToken);

    const jwkCognitoKey = jwkCognitoKeys.find(({ kid }) => headerObj.kid === kid);

    if (jwkCognitoKey) {
        const pemCognitoKey = jwkToPem(jwkCognitoKey);

        return jws.JWS.verifyJWT(
            accessToken,
            pemCognitoKey,
            {
                alg: headerObj.alg,
            },
        );
    }

    return false;
};

const acquireNewAccessToken = async (refreshToken) => {
    const tokenRequestData = new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: process.env.VUE_APP_COGNITO_CLIENT_ID,
    });

    const tokenRequestHeaders = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    });

    const response = await fetch(tokenEndpointUrl, {
        method: 'POST',
        headers: tokenRequestHeaders,
        body: tokenRequestData,
    });

    if (!response.ok) {
        throw new Error('Invalid refresh token provided');
    }

    const token = await response.json();

    if (token.error) {
        throw new Error('Invalid refresh token provided');
    }

    return token;
};

const acquireTokenFromCodeQueryParameter = async () => {
    const parsedSearchQuery = new URLSearchParams(window.location.search);

    const code = parsedSearchQuery.get('code');

    if (code) {
        const tokenRequestData = new URLSearchParams({
            code,
            client_id: process.env.VUE_APP_COGNITO_CLIENT_ID,
            redirect_uri: window.location.origin,
            grant_type: 'authorization_code',
        });

        const tokenRequestHeaders = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        });

        const response = await fetch(tokenEndpointUrl, {
            method: 'POST',
            headers: tokenRequestHeaders,
            body: tokenRequestData,
        });

        if (!response.ok) {
            throw new Error('Provided code parameter is invalid');
        }

        const token = await response.json();

        if (token.error) {
            throw new Error('Provided code parameter is invalid');
        }

        return token;
    }

    throw new Error('Code parameter does not exist in search query');
};

const isTokenExpired = (accessToken) => {
    const { payloadObj } = jws.JWS.parse(accessToken);

    const tokenExpirationTimestamp = payloadObj.exp;

    const currentDate = new Date();

    const currentUnixTimestampInSeconds = Math.floor(currentDate.getTime() / 1000);

    return currentUnixTimestampInSeconds > tokenExpirationTimestamp;
};

const loadTokens = () => ({
    accessToken: sessionStorage.getItem('access_token'),
    refreshToken: sessionStorage.getItem('refresh_token'),
});

const saveTokens = ({ accessToken, refreshToken }) => {
    sessionStorage.setItem('access_token', accessToken);
    sessionStorage.setItem('refresh_token', refreshToken);
};

const clearTokens = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
};

export const handleTokenAuthorization = async () => {
    const {
        accessToken: storedAccessToken,
        refreshToken: storedRefreshToken,
    } = loadTokens();

    if (storedAccessToken && storedRefreshToken) {
        const isAccessTokenValid = await checkAccessTokenValidity(storedAccessToken);

        if (!isAccessTokenValid) {
            const {
                access_token: accessToken,
            } = await acquireNewAccessToken(storedRefreshToken);

            saveTokens({
                accessToken,
                refreshToken: storedRefreshToken,
            });
        }
    } else {
        const {
            access_token: accessToken,
            refresh_token: refreshToken,
        } = await acquireTokenFromCodeQueryParameter();

        saveTokens({
            accessToken,
            refreshToken,
        });
    }
};

const redirectToEndpoint = (endpoint) => {
    const {
        VUE_APP_COGNITO_DOMAIN_URL,
        VUE_APP_COGNITO_CLIENT_ID,
        VUE_APP_COGNITO_SCOPE,
    } = process.env;

    const hostedUiParams = new URLSearchParams({
        client_id: VUE_APP_COGNITO_CLIENT_ID,
        response_type: 'code',
        scope: VUE_APP_COGNITO_SCOPE,
        redirect_uri: window.location.origin,
    }).toString();

    const hostedUiUrl = `${VUE_APP_COGNITO_DOMAIN_URL}/${endpoint}?${hostedUiParams}`;

    window.location.assign(
        hostedUiUrl,
    );
};

export const redirectToLoginPage = () => {
    redirectToEndpoint('login');
};

export const logoutAndRedirect = () => {
    clearTokens();
    redirectToEndpoint('logout');
};

export const getAccessToken = async () => {
    const {
        accessToken,
        refreshToken,
    } = loadTokens();

    try {
        if (!accessToken) {
            throw new Error('Access token does not exist');
        }

        if (isTokenExpired(accessToken)) {
            const {
                access_token: newAccessToken,
            } = await acquireNewAccessToken(refreshToken);

            saveTokens({
                accessToken: newAccessToken,
                refreshToken,
            });

            return newAccessToken;
        }

        return accessToken;
    } catch {
        return null;
    }
};

const decodeUnicodeCharacters = (text) => text.split('').map(
    (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`,
).join('');

// docs/adl/2021-10-05_display_receiver_name.md
const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        decodeUnicodeCharacters(atob(base64)),
    );

    return JSON.parse(jsonPayload);
};

export const getCurrentUsername = () => {
    const {
        accessToken,
    } = loadTokens();

    const { username } = parseJwt(accessToken);
    return username;
};
