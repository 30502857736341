import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { handleTokenAuthorization, redirectToLoginPage } from '@/authentication';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import IPBlacklistPage from '@/pages/IPBlacklistPage.vue';
import TransactionsPage from '@/pages/TransactionsPage.vue';
import MerchantLockoutPage from '@/pages/MerchantLockoutPage.vue';
import BTBPage from '@/pages/BTBPage.vue';
import AlternativeBankIntervalsPage from '@/pages/AlternativeBankIntervalsPage.vue';
import Merchants from '@/pages/Merchants.vue';
import Banks from '@/pages/Banks.vue';
import BankAccounts from '@/pages/BankAccounts.vue';
import Ips from '@/pages/IP.vue';
import Fingerprints from '@/pages/Fingerprint.vue';
import Emails from '@/pages/Email.vue';

import App from './App.vue';

import '@/styles/index.scss';

handleTokenAuthorization()
    .then(() => {
        const app = createApp(App);

        const routes = [
            { path: '/ccg-transactions', component: TransactionsPage, name: 'Transactions' },
            { path: '/ip-blacklist', component: IPBlacklistPage, name: 'IPBlackList' },
            { path: '/merchant-lockout', component: MerchantLockoutPage, name: 'MerchantLockout' },
            { path: '/', component: BTBPage, name: 'BankToBankTransactions' },
            { path: '/intervals', component: AlternativeBankIntervalsPage, name: 'AlternativeBankIntervalsPage' },
            { path: '/merchants', component: Merchants, name: 'Merchants' },
            { path: '/banks', component: Banks, name: 'Banks' },
            { path: '/bank-accounts', component: BankAccounts, name: 'BankAccounts' },
            { path: '/ips', component: Ips, name: 'Ips' },
            { path: '/fingerprints', component: Fingerprints, name: 'Fingerprints' },
            { path: '/emails', component: Emails, name: 'Emails' },
        ];

        const router = createRouter({
            history: createWebHistory(),
            routes,
        });

        app.use(router);

        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: process.env.VUE_APP_SENTRY_DSN,
                integrations: [new VueIntegration({ app, attachProps: true, logErrors: true })],
            });
        }

        app.mount('#app');
    })
    .catch(() => {
        redirectToLoginPage();
    });
