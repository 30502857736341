<template>
  <div>
    <button
      class="button is-small is-primary mb-4"
      title="Add IP Address"
      @click="openAddIpModal"
    >
      Add IP Address
    </button>

    <div
      class="modal"
      :class="{'is-active': isAddIpModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">IP Address</label>
              <div class="control">
                <input
                  v-model="newIp.ipAddress"
                  class="input"
                  :class="{ 'is-danger': errors.ipAddress }"
                  type="text"
                  placeholder="Enter IP Address"
                >

                <p
                  v-if="errors.ipAddress"
                  class="help is-danger"
                >
                  {{ errors.ipAddress }}
                </p>
              </div>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="addIp"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isIPv4 } from 'is-ip';
import { inject, ref } from 'vue';

export default {
    emits: ['ipAdded'],

    async setup(_props, { emit }) {
        const {
            postIp,
        } = inject('ips');

        const newIp = ref({});
        const isLoading = ref(false);
        const isAddIpModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            newIp.value.ipAddress = '';
            isAddIpModalOpen.value = false;
        };

        const openAddIpModal = () => {
            isAddIpModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = ['ipAddress'];
            return !requiredFields.every((keyName) => (
                newIp.value[keyName]
                && newIp.value[keyName].trim()
            ));
        };

        const validate = () => {
            if (!isIPv4(newIp.value.ipAddress)) {
                errors.value.ipAddress = 'IP Address has to be valid.';
                return false;
            }

            return true;
        };

        const addIp = async () => {
            if (validate()) {
                await postIp(newIp.value);
                emit('ipAdded', newIp.value.ipAddress);
                closeModal();
            }
        };

        return {
            addIp,
            closeModal,
            errors,
            isAddIpModalOpen,
            isLoading,
            isSaveButtonActive,
            newIp,
            openAddIpModal,
        };
    },
};
</script>
