/* eslint-disable camelcase */
import { reactive, computed } from 'vue';

const directions = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
};

export default () => {
    const ordering = reactive({
        list: [],
    });

    const camelToSnake = (key) => key
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((element) => element.toLowerCase()).join('_');

    const toParameter = ({ column, direction }) => (
        `${direction === directions.DESCENDING ? '-' : ''}${camelToSnake(column)}`
    );

    const orderingList = computed(() => ordering.list.map(toParameter).join(','));

    const changeOrdering = ({ column, direction }) => {
        ordering.list = ordering.list.filter(
            (element) => element.column !== column,
        );

        ordering.list.unshift({ column, direction });
    };

    const sortAscendingBy = (column) => {
        changeOrdering({
            column,
            direction: directions.ASCENDING,
        });
    };

    const sortDescendingBy = (column) => {
        changeOrdering({
            column,
            direction: directions.DESCENDING,
        });
    };

    const removeSortingBy = (column) => {
        changeOrdering({
            column,
            direction: null,
        });
    };

    return {
        orderingList,
        directions,
        sortAscendingBy,
        sortDescendingBy,
        removeSortingBy,
    };
};
