<template>
  <nav
    class="navbar is-primary"
    role="navigation"
  >
    <div class="navbar-brand">
      <router-link
        v-if="showCCGTransactions"
        class="navbar-item"
        :to="{ name: 'Transactions' }"
      >
        Credit Card Gateway - Admin Panel
      </router-link>
    </div>
    <div class="navbar-start">
      <router-link
        v-if="showNewIpView"
        class="navbar-item"
        :to="{ name: 'IPBlackList' }"
      >
        IP Blacklist
      </router-link>
      <router-link
        v-if="showMerchantLockout"
        class="navbar-item"
        :to="{ name: 'MerchantLockout' }"
      >
        Lock merchant
      </router-link>
      <router-link
        v-if="showBTBTransactions"
        class="navbar-item"
        :to="{ name: 'BankToBankTransactions' }"
      >
        Transactions
      </router-link>
      <router-link
        v-if="showAlternativeBankIntervalsTab()"
        class="navbar-item"
        :to="{ name: 'AlternativeBankIntervalsPage' }"
      >
        Alternative Bank Intervals
      </router-link>
      <router-link
        v-if="bankRotationEnabled"
        class="navbar-item"
        :to="{ name: 'Banks' }"
      >
        Banks
      </router-link>
      <router-link
        v-if="bankRotationEnabled"
        class="navbar-item"
        :to="{ name: 'BankAccounts' }"
      >
        Bank Accounts
      </router-link>
      <router-link
        v-if="bankRotationEnabled"
        class="navbar-item"
        :to="{ name: 'Merchants' }"
      >
        Merchants
      </router-link>

      <router-link
        class="navbar-item"
        :to="{ name: 'Ips' }"
      >
        IP Blacklist
      </router-link>

      <router-link
        class="navbar-item"
        :to="{ name: 'Fingerprints' }"
      >
        Client ID Blacklist
      </router-link>

      <router-link
        class="navbar-item"
        :to="{ name: 'Emails' }"
      >
        Email Blacklist
      </router-link>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a
            v-if="showStatisticsLink"
            class="button is-primary"
            href="https://monitor.d-paytech.com/d/Ui_eqxUGz/dashboard?orgId=1"
            target="_blank"
          >
            Statistics
          </a>
          <a
            class="button is-primary"
            @click="logoutAndRedirect"
          >
            <strong>Log out</strong>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logoutAndRedirect } from '@/authentication';

import initializeFlagsmith from '../flagsmith';

export default {
    name: 'NavBar',

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const showNewIpView = flagsmith.hasFeature('new_ip_blacklist_view');
        const showStatisticsLink = flagsmith.hasFeature('statistics_panel_link');
        const showMerchantLockout = flagsmith.hasFeature('locking_merchant_endpoint');
        const showBTBTransactions = flagsmith.hasFeature('btb_admin_panel');
        const showCCGTransactions = flagsmith.hasFeature('ccg_admin_panel');
        const showAlternativeBankIntervals = flagsmith.hasFeature('btb_bank_intervals_page');
        const bankRotationEnabled = flagsmith.hasFeature('new_bank_rotation');

        const showAlternativeBankIntervalsTab = () => {
            if (bankRotationEnabled) return false;

            if (showAlternativeBankIntervals) return true;

            return false;
        };

        return {
            logoutAndRedirect,
            showNewIpView,
            showStatisticsLink,
            showMerchantLockout,
            showBTBTransactions,
            showCCGTransactions,
            showAlternativeBankIntervals,
            bankRotationEnabled,
            showAlternativeBankIntervalsTab,
        };
    },
};
</script>
