<template>
  <div class="table-container">
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>Merchant ID</th>
          <th>Merchant Name</th>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="merchant in merchants"
          :key="merchant.merchantId"
        >
          <td>{{ merchant.merchantId }}</td>
          <td>{{ merchant.name }}</td>
          <td>
            <ManageBankAccounts
              :id="merchant.merchantId"
              :bank-accounts="allBankAccounts"
              group="FTD"
            />
          </td>
          <td>
            <ManageBankAccounts
              :id="merchant.merchantId"
              :bank-accounts="allBankAccounts"
              group="NonFTD"
            />
          </td>
          <td>
            <ManageBlockade
              :id="merchant.merchantId"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from 'vue';

import ManageBankAccounts from './ManageBankAccounts.vue';
import ManageBlockade from './ManageBlockade.vue';

export default {
    components: {
        ManageBankAccounts,
        ManageBlockade,
    },

    async setup() {
        const {
            getMerchants,
        } = inject('merchants');

        const {
            fetchBankAccounts,
        } = inject('bank_accounts');

        const merchants = await getMerchants();
        const allBankAccounts = await fetchBankAccounts();

        return {
            allBankAccounts,
            merchants,
        };
    },
};
</script>
