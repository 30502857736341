<template>
  <p>Start</p>
  <TimeInput
    :time="startTime()"
    :change-time="updateStartTime"
  />
  <p>End</p>
  <TimeInput
    :time="endTime()"
    :change-time="updateEndTime"
  />
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { defineProps, inject, watch } from 'vue';
import TimeInput from '../common/TimeInput.vue';

const { alternativeBankTime } = inject('alternative_bank');
const props = defineProps({
    day: {
        type: Object,
        required: true,
    },
});

const updateStartTime = (time) => {
    alternativeBankTime.value[props.day.id].start = time;
};

const updateEndTime = (time) => {
    alternativeBankTime.value[props.day.id].end = time;
};

const startTime = () => alternativeBankTime.value[props.day.id].start;
const endTime = () => alternativeBankTime.value[props.day.id].end;

</script>
