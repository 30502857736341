<template>
  <div>
    <button
      class="button is-small is-primary mb-4"
      title="Add Bank"
      @click="openAddBankModal"
    >
      Add Bank
    </button>

    <div
      class="modal"
      :class="{'is-active': isAddBankModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">Bank Name</label>
              <div class="control">
                <input
                  v-model="newBank.name"
                  class="input"
                  type="text"
                  placeholder="Enter bank name"
                >
              </div>
            </div>

            <div class="field is-full column">
              <label class="label">Bank Code</label>
              <div class="control">
                <input
                  v-model="newBank.code"
                  class="input"
                  :class="{ 'is-danger': errors.bankCode }"
                  type="text"
                  placeholder="Enter bank code"
                >

                <p
                  v-if="errors.bankCode"
                  class="help is-danger"
                >
                  {{ errors.bankCode }}
                </p>
              </div>
            </div>

            <div class="field is-full column">
              <label class="checkbox">
                <input
                  v-model="newBank.maintanance"
                  type="checkbox"
                >
                Under Maintanance
              </label>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="addBank"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    emits: ['bankAdded'],

    async setup(_props, { emit }) {
        const {
            postBank,
        } = inject('banks');

        const newBank = ref({});
        const isLoading = ref(false);
        const isAddBankModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            newBank.value.name = '';
            newBank.value.code = '';
            newBank.value.maintanance = false;
            isAddBankModalOpen.value = false;
        };

        const openAddBankModal = () => {
            isAddBankModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = ['name', 'code'];
            return !requiredFields.every((keyName) => (
                newBank.value[keyName]
                && newBank.value[keyName].trim()
            ));
        };

        const validate = () => {
            if (!/^\d+$/.test(newBank.value.code)) {
                errors.value.bankCode = 'Bank Code should contain only digits.';
                return false;
            }

            if (newBank.value.code.length !== 4) {
                errors.value.bankCode = 'Bank Code should have four digits.';
                return false;
            }

            return true;
        };

        const addBank = async () => {
            if (validate()) {
                const addedBank = await postBank(newBank.value);
                emit('bankAdded', addedBank);
                closeModal();
            }
        };

        return {
            addBank,
            closeModal,
            errors,
            isAddBankModalOpen,
            isLoading,
            isSaveButtonActive,
            newBank,
            openAddBankModal,
        };
    },
};
</script>
