<template>
  <div
    class="modal is-active"
  >
    <div
      class="modal-background"
      @click="closeModal"
    />
    <div class="modal-content">
      <div class="box">
        <div class="field is-half column">
          <label class="label">Merchant ID</label>
          <div class="control">
            <Suspense>
              <MerchantList
                ref="selectedId"
                label="Merchant ID"
                :is-disabled="true"
                @change="setMerchantId"
              />
            </Suspense>
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <p class="control">
            <button
              class="button is-primary"
              :disabled="isButtonActive()"
              @click="openEdit(transaction, merchantId)"
            >
              Save
            </button>
          </p>
          <p class="control">
            <a
              class="button is-light"
              @click="closeModal"
            >
              Cancel
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import MerchantList from '../BTBFilterForm/MerchantList.vue';

export default {
    props: {
        transaction: {
            type: Object,
            required: true,
        },
        merchant: {
            type: String,
            required: true,
        },
        openEdit: {
            type: Function,
            required: true,
        },
    },
    emits: ['close-modal'],
    setup(props, { emit }) {
        const selectedId = ref();
        const merchantId = ref('');
        const isLoading = ref(false);

        const setMerchantId = () => {
            merchantId.value = selectedId.value.selected;
        };

        const isButtonActive = () => !selectedId.value;

        const closeModal = () => {
            emit('close-modal');
        };

        watch(selectedId, (newselectedId) => {
            if (newselectedId) {
                selectedId.value.selected = props.merchant;
                merchantId.value = props.merchant;
            }
        }, { immediate: true });

        return {
            MerchantList,
            selectedId,
            merchantId,
            setMerchantId,
            isButtonActive,
            isLoading,
            closeModal,
        };
    },
};
</script>
