<template>
  <div>
    <button
      class="button is-small is-primary mb-4"
      title="Add E-mail"
      @click="openAddEmailModal"
    >
      Add E-mail
    </button>

    <div
      class="modal"
      :class="{'is-active': isAddEmailModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">E-mail Address</label>
              <div class="control">
                <input
                  v-model="newEmail.email"
                  class="input"
                  type="text"
                  placeholder="Enter E-mail Address"
                >
              </div>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="addEmail"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    emits: ['emailAdded'],

    async setup(_props, { emit }) {
        const {
            postEmail,
        } = inject('emails');

        const newEmail = ref({});
        const isLoading = ref(false);
        const isAddEmailModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            newEmail.value.ipAddress = '';
            isAddEmailModalOpen.value = false;
        };

        const openAddEmailModal = () => {
            isAddEmailModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = ['email'];
            return !requiredFields.every((keyName) => (
                newEmail.value[keyName]
                && newEmail.value[keyName].trim()
            ));
        };

        const addEmail = async () => {
            await postEmail(newEmail.value);
            emit('emailAdded', newEmail.value.email);
            closeModal();
        };

        return {
            addEmail,
            closeModal,
            errors,
            isAddEmailModalOpen,
            isLoading,
            isSaveButtonActive,
            newEmail,
            openAddEmailModal,
        };
    },
};
</script>
