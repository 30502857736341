<template>
  <div class="control is-expanded">
    <multiselect
      v-model="internalSelectedValue"
      :value="selectedValue"
      track-by="name"
      label="name"
      :placeholder="placeholder"
      :multiple="multiple"
      :options="options"
      :searchable="true"
      :close-on-select="false"
      @select="handleInput"
      @remove="handleRemoval"
    />
  </div>
</template>

<script>
import { ref, toRaw } from 'vue';

import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect,
    },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: 'Select',
        },
        sendParameter: {
            type: String,
            required: false,
            default: 'id',
        },
        selectedValue: {
            type: Array,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['change'],
    async setup(props) {
        const values = ref([]);
        const parameter = ref(props.sendParameter);
        const isMultiple = ref(props.multiple);

        return {
            values,
            parameter,
            isMultiple,
        };
    },
    data() {
        return {
            internalSelectedValue: this.selectedValue,
        };
    },
    methods: {
        watch: {
            selectedValue(newVal) {
                this.internalSelectedValue = newVal;
            },
            internalSelectedValue(newVal) {
                this.$emit('change', newVal);
            },
        },
        handleInput(value) {
            if (this.isMultiple) {
                this.values.push(value);
                this.$emit('change', toRaw(this.values));
            } else {
                this.$emit('change', toRaw(value));
            }
        },
        handleRemoval(value) {
            if (this.isMultiple) {
                const raw = toRaw(value);
                this.values = this.values.filter(
                    (el) => el[this.parameter] !== raw[this.parameter],
                );
                this.$emit('change', toRaw(this.values));
            } else {
                this.$emit('change', '');
            }
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.multiselect__select::before {
  visibility: hidden;
}
.multiselect__option::after {
  font-size: inherit;
}
.multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: inherit;
  }
  .multiselect__tags {
    font-size: inherit;
    padding: 4px 40px 0 calc(0.75em - 1px);
    border: 1px solid #dbdbdb;
  }
  .multiselect {
    /* z-index: 1; */
  }
  .multiselect-height {
    height: auto ;
  }
</style>
