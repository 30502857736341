import { ref } from 'vue';
import { httpGet, httpPost } from './http';

const BANK_INTERVALS_PATH = 'intervals/';

export default () => {
    const alternativeBankTime = ref({
        1: {
            start: '00:00',
            end: '00:00',
        },
        2: {
            start: '00:00',
            end: '00:00',
        },
        3: {
            start: '00:00',
            end: '00:00',
        },
        4: {
            start: '00:00',
            end: '00:00',
        },
        5: {
            start: '00:00',
            end: '00:00',
        },
        6: {
            start: '00:00',
            end: '00:00',
        },
        7: {
            start: '00:00',
            end: '00:00',
        },
    });

    const intervalError = ref('');
    const intervalSuccess = ref('');

    const fetchAlternativeBankTime = async () => {
        intervalSuccess.value = '';
        const response = await httpGet(BANK_INTERVALS_PATH);
        alternativeBankTime.value = response;
    };

    const postAlternativeBankTime = async () => {
        try {
            await httpPost(BANK_INTERVALS_PATH, {}, alternativeBankTime.value);

            fetchAlternativeBankTime();

            intervalError.value = '';
            intervalSuccess.value = 'Data saved correctly';
        } catch (error) {
            intervalError.value = 'Start time cannot be after end time.';
            intervalSuccess.value = '';
        }
    };

    const resetAlternativeBankTime = () => {
        Array.from(Array(7)).forEach((_, index) => {
            alternativeBankTime.value[index + 1] = {
                start: '00:00',
                end: '00:00',
            };
        });
        postAlternativeBankTime();
    };

    return {
        fetchAlternativeBankTime,
        postAlternativeBankTime,
        alternativeBankTime,
        resetAlternativeBankTime,
        intervalError,
        intervalSuccess,
    };
};
