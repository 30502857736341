<template>
  <div>
    <button
      class="button is-small is-danger"
      title="Delete E-mail Address"
      @click="openModal"
    >
      Delete E-mail Address
    </button>

    <div
      class="modal"
      :class="{'is-active': modalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div
            class="has-text-centered is-size-3-tablet mb-5"
          >
            <b>Are you sure to delete this E-mail Address?</b>
          </div>

          <div class="field is-grouped is-grouped-right">
            <p class="control">
              <button
                class="button is-primary"
                :class="{ 'is-loading': isLoading }"
                @click="deleteItem"
              >
                Delete
              </button>
            </p>
            <p class="control">
              <a
                class="button is-light"
                @click="closeModal"
              >
                Cancel
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    props: {
        item: {
            type: String,
            default: '',
        },
    },

    emits: ['itemDeleted'],

    async setup(props, { emit }) {
        const {
            deleteEmail,
        } = inject('emails');

        const modalOpen = ref(false);
        const isLoading = ref(false);

        const openModal = () => {
            modalOpen.value = true;
        };

        const closeModal = () => {
            modalOpen.value = false;
        };

        const deleteItem = async () => {
            await deleteEmail({ email: props.item });
            emit('itemDeleted', props.item);
            closeModal();
        };

        return {
            deleteItem,
            openModal,
            closeModal,
            modalOpen,
            isLoading,
        };
    },
};
</script>
