<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            value=""
            selected
          >
            All
          </option>
          <option value="ACCEPTED">
            Accepted
          </option>
          <option value="REJECTED">
            Rejected
          </option>
          <option value="FAILED">
            Failed
          </option>
          <option value="FAILED_WITH_UNAUTHORIZED_EMAIL">
            Unauthorized
          </option>
          <option
            v-if="shouldDisplayBankDetails"
            value="FAILED_WITH_UNAUTHORIZED_CODE"
          >
            Unauthorized Code
          </option>
          <option
            v-if="shouldDisplayCaptchaStatus"
            value="FAILED_WITH_INVALID_CAPTCHA"
          >
            Invalid CAPTCHA
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import initializeFlagsmith from '../flagsmith';

export default {
    props: {
        shouldDisplayBankDetails: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:status'],

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const shouldDisplayCaptchaStatus = flagsmith.hasFeature('use_captcha');

        return {
            shouldDisplayCaptchaStatus,
        };
    },
};
</script>
