<template>
  <div>
    <div class="select is-multiple is-fullwidth">
      <div class="control is-expanded">
        <multi-select
          :selected-value="[]"
          :options="currencies"
          :multiple="false"
          placeholder="Currency Flow"
          send-parameter="value"
          @change="handleChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from '../common/MultiSelect.vue';

export default {
    components: { MultiSelect },
    emits: ['update:status'],
    async setup() {
        const currencies = [
            {
                id: 1,
                name: 'USD/JPY',
                value: 'usd',
            },
            {
                id: 2,
                name: 'JPY/JPY',
                value: 'jpy',
            },
        ];
        return {
            currencies,
        };
    },
    methods: {
        handleChange(value) {
            this.$emit('update:status', value.value);
        },
    },
};
</script>
