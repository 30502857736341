import { DateTime } from 'luxon';

import initializeFlagsmith from '../flagsmith';
import { httpGet, httpPost } from '../http';

export default () => {
    const getMerchants = async () => {
        const cachedData = localStorage.getItem('cached-merchants');

        if (cachedData) {
            const parsed = JSON.parse(cachedData);
            const expires = DateTime.fromMillis(parsed.expires);

            if (expires > DateTime.now()) {
                return parsed.data;
            }
        }

        const flagsmith = await initializeFlagsmith();

        const merchants = flagsmith
            .getValue('merchants_list')
            .split(' ')
            .map((merchant) => {
                const [merchantId, name] = merchant.split(',');

                return {
                    merchantId,
                    name,
                };
            });

        localStorage.setItem(
            'cached-merchants',
            JSON.stringify(
                {
                    expires: DateTime.now().plus({ minutes: 15 }).ts,
                    data: merchants,
                },
            ),
        );

        return merchants;
    };

    const camelToSnakeBlockade = (data) => ({
        enabled: data.enabled,
        interval_in_days: data.interval,
    });

    const snakeToCamelBlockade = (res) => (
        {
            interval: res.interval_in_days,
            enabled: res.enabled,
        }
    );

    const sendBlockadeData = async (merchantId, data) => {
        const response = await httpPost(
            `merchants/blockade-status/${merchantId}/`,
            {},
            camelToSnakeBlockade(data),
        );

        return response;
    };

    const getBlockadeData = async (merchantId) => {
        const response = await httpGet(
            `merchants/blockade-status/${merchantId}/`,
        );

        return snakeToCamelBlockade(response);
    };

    return {
        getMerchants,
        getBlockadeData,
        sendBlockadeData,
    };
};
