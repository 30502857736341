<template>
  <Suspense>
    <template #default>
      <FingerprintList />
    </template>
    <template #fallback>
      Loading...
    </template>
  </Suspense>
</template>

<script>
import FingerprintList from '@/FingerprintList/Index.vue';

export default {
    components: {
        FingerprintList,
    },
};
</script>
