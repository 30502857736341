<template>
  <span
    class="icon"
    :class="{
      'has-text-success': isSuccessfull,
      'has-text-info': isRefund,
      'has-text-danger': isFailed || isUnauthorized || isUnauthorizedCode || isInvalidCaptcha,
      'has-text-warning': isUnknown,
    }"
  >
    <i
      class="fas fa-lg"
      :class="{
        'fa-check-circle': isSuccessfull,
        'fa-minus-circle': isFailed,
        'fa-arrow-alt-circle-left': isRefund,
        'fa-question-circle': isUnknown,
        'fa-key': isUnauthorized,
        'fa-money-check': isUnauthorizedCode,
        'fa-keyboard': isInvalidCaptcha,
      }"
      :title="status"
    />
  </span>
</template>

<script>
const SUCCESS = 'ACCEPTED';
const REJECTED = 'REJECTED';
const REFUND = 'REFUND';
const FAILED = 'FAILED';
const UNAUTHORIZED = 'FAILED_WITH_UNAUTHORIZED_EMAIL';
const UNAUTHORIZED_CODE = 'FAILED_WITH_UNAUTHORIZED_CODE';
const INVALID_CAPTCHA = 'FAILED_WITH_INVALID_CAPTCHA';

export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },

    computed: {
        isSuccessfull() {
            return this.status === SUCCESS;
        },

        isFailed() {
            return this.status === REJECTED || this.status === FAILED;
        },

        isRefund() {
            return this.status === REFUND;
        },

        isUnauthorized() {
            return this.status === UNAUTHORIZED;
        },

        isUnauthorizedCode() {
            return this.status === UNAUTHORIZED_CODE;
        },

        isInvalidCaptcha() {
            return this.status === INVALID_CAPTCHA;
        },

        isUnknown() {
            return (
                !this.isSuccessfull
                && !this.isFailed
                && !this.isRefund
                && !this.isUnauthorized
                && !this.isUnauthorizedCode
                && !this.isInvalidCaptcha
            );
        },
    },
};
</script>
