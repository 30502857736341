import { httpGet, httpPost } from '../http';

export default () => {
    const getFingerprints = async () => {
        const response = await httpGet('blocked-fingerprints/');
        return response;
    };

    const camelToSnake = (data) => ({
        fingerprint: data.fingerprint,
    });

    const postFingerprint = async (data) => (
        httpPost('blocked-fingerprints/', {}, camelToSnake(data))
    );

    const deleteFingerprint = async (data) => (
        httpPost('blocked-fingerprints/delete/', {}, camelToSnake(data))
    );

    return {
        getFingerprints,
        postFingerprint,
        deleteFingerprint,
    };
};
