<template>
  <div>
    <div class="select is-fullwidth">
      <div
        v-if="isDisabled"
        class="control is-expanded"
      >
        <select
          v-model="selected"
          disabled
          readonly
        >
          <option
            v-if="showAllOption"
            value=""
            selected
          >
            All
          </option>
          <option
            v-else
            value=""
            selected
            disabled
          >
            Select merchant
          </option>
          <option
            v-for="merchant in merchants"
            :key="merchant[0]"
            :value="merchant[0]"
          >
            {{ merchant[1] }}
          </option>
        </select>
      </div>
      <div
        v-else
        class="control is-expanded"
      >
        <select
          v-model="selected"
        >
          <option
            v-if="showAllOption"
            value=""
            selected
          >
            All
          </option>
          <option
            v-else
            value=""
            selected
            disabled
          >
            Select merchant
          </option>
          <option
            v-for="merchant in merchants"
            :key="merchant[0]"
            :value="merchant[0]"
          >
            {{ merchant[1] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import initializeFlagsmith from '../flagsmith';

export default {
    props: {
        showAllOption: {
            required: false,
            default: false,
            type: Boolean,
        },
        isDisabled: {
            required: false,
            default: false,
            type: Boolean,
        },
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();
        const selected = ref('');

        const merchants = flagsmith
            .getValue('merchants_list')
            .split(' ')
            .map((merchant) => merchant.split(','));

        return {
            merchants,
            selected,
        };
    },
};
</script>
