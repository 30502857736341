import { ref } from 'vue';

import { httpDelete } from '@/http';

const PATH = 'merchant';

export default () => {
    const merchantId = ref('');

    const performLockout = async () => {
        try {
            await httpDelete(`${PATH}/${merchantId.value}`);
            return true;
        } catch (_) {
            return false;
        }
    };

    const clearMerchantId = () => {
        merchantId.value = '';
    };

    return {
        merchantId,
        performLockout,
        clearMerchantId,
    };
};
