<template>
  <Suspense>
    <template #default>
      <BTBTransactions />
    </template>
    <template #fallback>
      Loading...
    </template>
  </Suspense>
</template>

<script>
import BTBTransactions from '@/main/BTBTransactions.vue';

export default {
    components: {
        BTBTransactions,
    },
};
</script>
