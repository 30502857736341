<template>
  <div class="box">
    <div class="field has-addons">
      <div class="control is-expanded">
        <input
          v-model="merchantId"
          class="input"
          type="text"
          placeholder="Enter merchant ID"
        >
      </div>
      <div class="control">
        <button
          class="button is-danger"
          :disabled="lockButtonDisabled"
          @click="confirmLockout"
        >
          Lock
        </button>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': isConfirmModalOpen }"
    >
      <div class="modal-background" />
      <div class="modal-content">
        <div class="box">
          <p class="is-5 has-text-centered">
            Are you sure to lock merchant with ID <b>{{ merchantId }}</b>?
          </p>
          <div class="buttons is-centered mt-3">
            <button
              class="button is-danger mx-4"
              @click="lockout"
            >
              Yes <i class="fas fa-check-circle ml-1" />
            </button>
            <button
              class="button is-success mx-4"
              @click="closeModal"
            >
              No <i class="fas fa-times-circle ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': isSuccessModalOpen }"
    >
      <div class="modal-background" />
      <div class="modal-content">
        <div class="box">
          <p class="is-5 has-text-centered">
            Merchant locked
            <span class="has-text-success has-text-weight-bold">successfully</span>.
            To unlock, please contact technical support.
          </p>
          <div class="buttons is-centered mt-3">
            <button
              class="button is-success mx-4"
              @click="closeModal"
            >
              OK <i class="fas fa-check-circle ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': isFailureModalOpen }"
    >
      <div class="modal-background" />
      <div class="modal-content">
        <div class="box">
          <p class="is-5 has-text-centered">
            <span class="has-text-danger has-text-weight-bold">Error</span>
            while disabling merchant: either merchant ID is invalid or it has been already locked.
          </p>
          <div class="buttons is-centered mt-3">
            <button
              class="button is-success mx-4"
              @click="closeModal"
            >
              OK <i class="fas fa-check-circle ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';

export default {
    async setup() {
        const CONFIRMATION_MODAL = 'confirm';
        const SUCCESS_MODAL = 'success';
        const FAILURE_MODAL = 'failure';

        const {
            merchantId,
            performLockout,
            clearMerchantId,
        } = inject('merchant_lockout');

        const activeModal = ref('');

        const lockButtonDisabled = computed(() => merchantId.value.length === 0);

        const confirmLockout = () => {
            activeModal.value = CONFIRMATION_MODAL;
        };
        const announceSuccess = () => {
            activeModal.value = SUCCESS_MODAL;
        };
        const announceFailure = () => {
            activeModal.value = FAILURE_MODAL;
        };
        const lockout = async () => {
            if (await performLockout()) {
                announceSuccess();
                clearMerchantId();
            } else {
                announceFailure();
            }
        };
        const closeModal = () => {
            activeModal.value = '';
        };

        const isConfirmModalOpen = computed(() => activeModal.value === CONFIRMATION_MODAL);
        const isSuccessModalOpen = computed(() => activeModal.value === SUCCESS_MODAL);
        const isFailureModalOpen = computed(() => activeModal.value === FAILURE_MODAL);

        return {
            merchantId,
            lockButtonDisabled,

            isConfirmModalOpen,
            isSuccessModalOpen,
            isFailureModalOpen,

            confirmLockout,
            lockout,
            closeModal,
        };
    },
};
</script>
