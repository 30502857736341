<template>
  <div class="box">
    <BTBFilterForm />
    <BTBTransactionList />
  </div>
</template>

<script>
import BTBFilterForm from '@/BTBFilterForm/Index.vue';
import BTBTransactionList from '@/BTBTransactionList/Index.vue';

export default {
    components: {
        BTBFilterForm,
        BTBTransactionList,
    },
};
</script>
