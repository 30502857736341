<template>
  <div class="my-3">
    <button
      class="button is-small is-primary"
      title="Manage Blockade"
      @click="openModal"
    >
      Manage Blockade
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
      />

      <div
        class="modal-content"
        style="width: 600px;"
      >
        <div class="box">
          <button
            class="delete is-pulled-right"
            aria-label="close"
            @click="closeModal"
          />

          <div class="is-flex is-flex-wrap-wrap columns my-4 mx-4">
            <div class="field column is-half">
              <div class="control">
                <label class="checkbox">
                  <input
                    v-model="blockadeEnabled"
                    type="checkbox"
                  >
                  Blockade enabled
                </label>
              </div>
            </div>

            <div class="field column is-half">
              <label class="label">Block period in days</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    v-model="interval"
                    :disabled="!blockadeEnabled"
                  >
                    <option
                      value="0"
                      selected
                    >
                      -----
                    </option>

                    <option
                      value="30"
                      selected
                    >
                      30
                    </option>

                    <option
                      value="60"
                      selected
                    >
                      60
                    </option>

                    <option
                      value="120"
                      selected
                    >
                      120
                    </option>

                    <option
                      value="180"
                      selected
                    >
                      180
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="has-text-right my-2 container is-fullhd"
            >
              <button
                class="button is-primary is-success mr-2"
                @click="save"
              >
                Save
              </button>
              <button
                class="button is-light"
                @click="closeModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    props: {
        id: {
            type: String,
            default: null,
        },
    },

    async setup(props) {
        const {
            getBlockadeData,
            sendBlockadeData,
        } = inject('merchants');

        const isModalOpen = ref(false);
        const interval = ref('0');
        const blockadeEnabled = ref(false);

        const closeModal = () => {
            isModalOpen.value = false;
        };

        const openModal = async () => {
            const data = await getBlockadeData(props.id);

            interval.value = data.interval;
            blockadeEnabled.value = data.enabled;

            isModalOpen.value = true;
        };

        const save = async () => {
            await sendBlockadeData(
                props.id,
                {
                    enabled: blockadeEnabled.value,
                    interval: blockadeEnabled.value ? interval.value : '0',
                },
            );

            closeModal();
        };

        return {
            blockadeEnabled,
            closeModal,
            isModalOpen,
            interval,
            openModal,
            save,
        };
    },
};
</script>
