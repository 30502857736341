<template>
  <div class="table-container">
    <AddFingerprint @fingerprint-added="fingerprintAdded" />

    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>Client ID</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="fingerprint in fingerprints"
          :key="fingerprint"
        >
          <td>{{ fingerprint }}</td>
          <td>
            <DeleteFingerprint
              :item="fingerprint"
              @item-deleted="fingerprintDeleted"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import AddFingerprint from './AddFingerprint.vue';
import DeleteFingerprint from './DeleteFingerprint.vue';

export default {
    components: {
        AddFingerprint,
        DeleteFingerprint,
    },

    async setup() {
        const fingerprints = ref([]);

        const {
            getFingerprints,
        } = inject('fingerprints');

        fingerprints.value = await getFingerprints();

        const fingerprintAdded = (addedFingerprint) => {
            fingerprints.value = [...fingerprints.value, addedFingerprint];
        };

        const fingerprintDeleted = (deletedFp) => {
            fingerprints.value = fingerprints.value.filter((fp) => fp !== deletedFp);
        };

        return {
            fingerprints,
            fingerprintAdded,
            fingerprintDeleted,
        };
    },
};
</script>
