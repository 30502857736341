<template>
  <div>
    <div class="select is-multiple is-fullwidth">
      <div class="control is-expanded">
        <multi-select
          :selected-value="[]"
          :options="merchantList"
          placeholder="Merchant ID"
          @change="handleChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from '../common/MultiSelect.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    components: { MultiSelect },
    emits: ['update:status'],

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const merchants = flagsmith
            .getValue('merchants_list')
            .split(' ')
            .map((merchant) => merchant.split(','));

        const merchantList = merchants.map((merchant) => ({
            name: merchant[1],
            id: merchant[0],
        }));

        return {
            merchants,
            merchantList,
        };
    },
    methods: {
        handleChange(value) {
            this.$emit('update:status', value);
        },
    },
};
</script>
