<template>
  <tr
    class="is-centered"
    :class="{
      'has-background-danger-light has-text-danger-dark': isLockedIp(transaction.ipAddress),
      'is-not-wrapped': shouldRemoveWrapping,
    }"
  >
    <td>{{ transaction.transactionId }}</td>
    <td v-if="shouldDisplayCardDetails">
      {{ transaction.cardDetails }}
    </td>
    <td>{{ transaction.cardId }}</td>
    <td>{{ transaction.email }}</td>
    <td v-if="shouldDisplayNames">
      {{ transaction.firstName }}
    </td>
    <td v-if="shouldDisplayNames">
      {{ transaction.surname }}
    </td>
    <td>{{ transaction.senderName }}</td>
    <td v-if="shouldDisplayNonce">
      {{ transaction.nonce }}
    </td>
    <td v-if="shouldDisplayBankDetails">
      {{ transaction.bankName }}
    </td>
    <td v-if="shouldDisplayBankDetails">
      {{ transaction.bankCode }}
    </td>
    <td>{{ transaction.ipAddress }}</td>
    <td>{{ formatAmount(transaction.amount, transaction.currency) }}</td>
    <td>{{ transaction.merchantId }}</td>
    <td v-if="shouldDisplayMid">
      {{ transaction.mid }}
    </td>
    <td>{{ dateToUTC(transaction.timestamp) }}</td>
    <td>
      <StatusIcon
        :status="transaction.status"
      />
    </td>
    <td>{{ formatAmount(transaction.processingFee, transaction.targetCurrency) }}</td>
    <td>{{ formatAmount(transaction.enteredAmount, transaction.targetCurrency) }}</td>
    <td>
      <RefundTransaction
        v-if="transaction.status == 'ACCEPTED'"
        :transaction-id="transaction.transactionId"
      />
    </td>
    <td>
      <button
        v-if="isLockedIp(transaction.ipAddress)"
        class="button is-small is-success"
        title="Unlock IP address"
        @click="unblockIp(transaction.ipAddress)"
      >
        <span class="icon">
          <i class="fas fa-lock-open" />
        </span>
      </button>
      <button
        v-else
        class="button is-small is-danger"
        title="Lock IP address"
        @click="blockIp(transaction.ipAddress)"
      >
        <span class="icon">
          <i class="fas fa-lock" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { inject } from 'vue';

import StatusIcon from '../common/StatusIcon.vue';
import RefundTransaction from './RefundTransaction.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    components: {
        StatusIcon,
        RefundTransaction,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },

    async setup() {
        const {
            blockIp,
            blockedIpList,
            isLockedIp,
            unblockIp,
        } = inject('blocked_ips');

        const formatAmount = (amount, curr) => new Intl.NumberFormat(
            'en-US', { style: 'currency', currency: curr },
        ).format(amount / 100);

        const dateToUTC = (date) => date.toUTCString();

        const flagsmith = await initializeFlagsmith();

        const shouldDisplayCardDetails = flagsmith.hasFeature('should_display_card_details_admin');
        const shouldRemoveWrapping = flagsmith.hasFeature('new_ip_blacklist_view');
        const shouldDisplayBankDetails = flagsmith.hasFeature('check_bank');
        const shouldDisplayMid = flagsmith.hasFeature('admin_mid');
        const shouldDisplayNonce = flagsmith.hasFeature('admin_nonce');
        const shouldDisplayNames = flagsmith.hasFeature('admin_ccg_names');

        return {
            formatAmount,
            dateToUTC,
            blockIp,
            blockedIpList,
            isLockedIp,
            unblockIp,
            shouldDisplayCardDetails,
            shouldRemoveWrapping,
            shouldDisplayBankDetails,
            shouldDisplayMid,
            shouldDisplayNonce,
            shouldDisplayNames,
        };
    },
};
</script>

<style>
.is-not-wrapped td {
  white-space: nowrap;
}
</style>
