<template>
  <div class="table-container">
    <AddIp @ip-added="ipAdded" />

    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>IP ADDRESS</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="ip in ips"
          :key="ip"
        >
          <td>{{ ip }}</td>
          <td>
            <DeleteIp
              :item="ip"
              @item-deleted="ipDeleted"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import AddIp from './AddIp.vue';
import DeleteIp from './DeleteIp.vue';

export default {
    components: {
        AddIp,
        DeleteIp,
    },

    async setup() {
        const ips = ref([]);

        const {
            getIps,
        } = inject('ips');

        ips.value = await getIps();

        const ipAdded = (addedIp) => {
            ips.value = [...ips.value, addedIp];
        };

        const ipDeleted = (deletedIp) => {
            ips.value = ips.value.filter((ip) => ip !== deletedIp);
        };

        return {
            ips,
            ipAdded,
            ipDeleted,
        };
    },
};
</script>
