import qs from 'querystringify';
import { getAccessToken } from './authentication';

const makeUrl = (url, params) => {
    const { VUE_APP_REQUESTS_BASE_URL } = process.env;
    const trailingSlash = url.endsWith('/') ? '' : '/';
    return `${VUE_APP_REQUESTS_BASE_URL}/${url}${trailingSlash}${qs.stringify(params, true)}`;
};

const getToken = async () => {
    const token = await getAccessToken();
    if (!token) {
        throw new Error('Cannot obtain authentication token');
    }
    return token;
};

export const getCsv = async (url, params = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${await getToken()}`,
            Accept: 'text/csv',
        },
    });
    if (!response.ok) {
        throw new Error(`Error when accessing data at ${url}`);
    }
    return response.blob();
};

export const httpGet = async (url, params = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${await getToken()}`,
        },
    });
    if (!response.ok) {
        throw new Error(`Error when accessing data at ${url}`);
    }
    return response.json();
};

export const httpPost = async (url, params = {}, body = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${await getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(`Error when posting data at ${url}`);
    } else {
        if (response.status === 204) {
            return response;
        }

        return response.json();
    }
};

export const httpPut = async (url, params = {}, body = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${await getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error(`Error when posting data at ${url}`);
    } else {
        if (response.status === 204) {
            return response;
        }

        return response.json();
    }
};

export const httpPatch = async (url, params = {}, body = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${await getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(`Error when puting data at ${url}`);
    }

    return response.json();
};

export const httpDelete = async (url, params = {}, init = {}) => {
    const response = await fetch(makeUrl(url, params), {
        ...init,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
    if (!response.ok) {
        throw new Error(`Error when deleting data at ${url}`);
    }
};
