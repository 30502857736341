<template>
  <nav
    class="pagination is-small is-centered mgt-large"
    role="navigation"
    aria-label="pagination"
  >
    <ul class="pagination-list">
      <li
        v-for="paginationItem in paginationItems"
        :key="paginationItem.position"
      >
        <a
          v-if="paginationItem.displayItem()"
          :class="paginationItem.class"
          :disabled="paginationItem.isDisabled"
          @click="changePage(paginationItem.value())"
        >{{ paginationItem.value() }}</a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
/* eslint-disable no-unused-vars */
/* this rule override is used due to <script setup> not accepting the export const variables */
import {
    defineProps, ref, reactive, inject, watch,
} from 'vue';

const props = defineProps({
    maxPage: { type: Number, required: true },
});

const {
    transactions: {
        fetchTransactions,
        currentPage,
    },
    filters,
} = inject('btb_transactions');

const FIRST_PAGE = 1;

const isOutOfRange = (position) => currentPage.value + position >= 1
  && currentPage.value + position <= props.maxPage;

const changePage = (value) => {
    currentPage.value = value;
    fetchTransactions(currentPage.value);
};

const paginationItems = reactive([
    {
        position: 1,
        displayItem: () => isOutOfRange(-2),
        value: () => FIRST_PAGE,
        isDisable: false,
        class: 'pagination-link',
    },
    {
        position: 2,
        displayItem: () => isOutOfRange(-3),
        value: () => '…',
        isDisable: true,
        class: 'pagination-ellipsis',
    },
    {
        position: 3,
        displayItem: () => isOutOfRange(-1),
        value: () => currentPage.value - 1,
        isDisable: false,
        class: 'pagination-link',
    },
    {
        position: 4,
        displayItem: () => true,
        value: () => currentPage.value,
        isDisable: true,
        class: 'pagination-link is-current',
    },
    {
        position: 5,
        displayItem: () => isOutOfRange(1),
        value: () => currentPage.value + 1,
        isDisable: false,
        class: 'pagination-link',
    },
    {
        position: 6,
        displayItem: () => isOutOfRange(3),
        value: () => '…',
        isDisable: true,
        class: 'pagination-ellipsis',
    },
    {
        position: 7,
        displayItem: () => isOutOfRange(2),
        value: () => props.maxPage,
        isDisable: false,
        class: 'pagination-link',
    },
]);
</script>
