import { ref } from 'vue';
import { httpGet, httpPost } from './http';

const BLACKLIST_PATH = 'ip/blacklist/';
const UNBLACKLIST_PATH = 'ip/unblacklist/';

const reqBody = (ip) => ({ address: ip.toString() });

export default () => {
    const blockedIpList = ref([]);

    const fetchBlockedIps = async () => {
        const response = await httpGet(BLACKLIST_PATH);
        blockedIpList.value = response;
    };

    const unblockIp = async (ip) => {
        const body = reqBody(ip);
        await httpPost(UNBLACKLIST_PATH, {}, body);
        fetchBlockedIps();
    };

    const blockIp = async (ip) => {
        const body = reqBody(ip);
        await httpPost(BLACKLIST_PATH, {}, body);
        fetchBlockedIps();
    };

    const isLockedIp = (ip) => blockedIpList.value.includes(ip);

    return {
        blockedIpList,
        fetchBlockedIps,
        unblockIp,
        blockIp,
        isLockedIp,
    };
};
