<template>
  <div class="my-3">
    <button
      class="button is-small is-primary"
      title="Manage Merchant Bank Accounts"
      @click="openModal"
    >
      Manage Merchant {{ group }} Bank Accounts
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
      />

      <div
        class="modal-content"
        style="width: 1000px;"
      >
        <div class="box">
          <button
            class="delete is-pulled-right"
            aria-label="close"
            @click="cancelModal"
          />
          <div class="is-flex is-flex-wrap-wrap columns my-4 mx-4">
            <div class="column is-half">
              <h4
                class="has-text-centered is-size-6 mb-4"
              >
                Available bank accounts
              </h4>
              <div class="card list-group-container">
                <draggable
                  class="dragArea list-group w-full has-text-centered p-5"
                  :list="disconnected"
                  group="items"
                  :move="moveDisconnected"
                  :sort="false"
                >
                  <div
                    v-for="bankAccount in disconnected"
                    :key="bankAccount.bankAccountId"
                    :value="bankAccount"
                    class="list-group-item bg-gray-300 m-1 column"
                    style="border: 1px solid black; border-radius: 5px;"
                  >
                    {{ bankAccount.accountNumber }}
                    / {{ bankAccount.bank.name }}
                    / {{ bankAccount.bank.code }}
                  </div>
                </draggable>
              </div>
            </div>

            <div class="column is-half">
              <h4
                class="has-text-centered is-size-6 mb-4"
              >
                Selected bank accounts
              </h4>
              <div class="card list-group-container">
                <draggable
                  class="dragArea list-group w-full has-text-centered p-5"
                  :list="connected"
                  group="items"
                  :move="moveConnected"
                  :sort="false"
                >
                  <div
                    v-for="bankAccount in connected"
                    :key="bankAccount.bankAccountId"
                    :value="bankAccount"
                    class="list-group-item bg-gray-300 m-1 column"
                    style="border: 1px solid black; border-radius: 5px;"
                  >
                    {{ bankAccount.accountNumber }}
                    / {{ bankAccount.bank.name }}
                    / {{ bankAccount.bank.code }}
                  </div>
                </draggable>
              </div>
            </div>

            <div
              class="has-text-right my-2 container is-fullhd"
            >
              <button
                class="button is-primary is-success mr-2"
                @click="closeModal"
              >
                Save
              </button>
              <button
                class="button is-light"
                @click="cancelModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
    components: {
        draggable: VueDraggableNext,
    },

    props: {
        id: {
            type: String,
            default: null,
        },
        bankAccounts: {
            type: Object,
            default: null,
        },
        group: {
            type: String,
            default: null,
        },
    },

    async setup(props) {
        const {
            connect,
            disconnect,
            fetchConnectedBanks,
        } = inject('bank_accounts');

        const isModalOpen = ref(false);
        const connected = ref([]);
        const disconnected = ref([]);

        // These two are used to not send too many connect / disconnect requests
        // I could use the `connected` and `disconnected` ref but then the dragable
        // components are doubled in the UI.
        const connectedReq = ref([]);
        const disconnectedReq = ref([]);

        const sentConnected = ref([]);
        const sentDisconnected = ref([]);

        const clearBankConnectionsStrages = () => {
            connected.value = [];
            connectedReq.value = [];
            disconnected.value = [];
            disconnectedReq.value = [];

            sentDisconnected.value = [];
            sentConnected.value = [];
        };

        const closeModal = () => {
            isModalOpen.value = false;

            clearBankConnectionsStrages();
        };

        const cancelModal = () => {
            sentDisconnected.value.forEach((bankAccountId) => (
                connect(
                    {
                        bankAccountId,
                        merchantId: props.id,
                        type: props.group,
                    },
                )
            ));

            sentConnected.value.forEach((bankAccountId) => (
                disconnect(
                    {
                        bankAccountId,
                        merchantId: props.id,
                        type: props.group,
                    },
                )
            ));

            isModalOpen.value = false;

            clearBankConnectionsStrages();
        };

        const openModal = async () => {
            const connectedIds = await fetchConnectedBanks(
                props.id, props.group,
            );
            clearBankConnectionsStrages();

            props.bankAccounts.forEach((bankAccount) => {
                if (connectedIds.includes(bankAccount.bankAccountId)) {
                    connected.value = [...connected.value, bankAccount];
                    connectedReq.value = [...connectedReq.value, bankAccount];
                } else {
                    disconnected.value = [...disconnected.value, bankAccount];
                    disconnectedReq.value = [...disconnectedReq.value, bankAccount];
                }
            });

            isModalOpen.value = true;
        };

        const moveConnected = ({ draggedContext }) => {
            if (!draggedContext.element) {
                return;
            }

            const contains = disconnectedReq.value
                .map((element) => element.bankAccountId)
                .includes(draggedContext.element.bankAccountId);

            if (contains) {
                return;
            }

            connectedReq.value = connectedReq.value.filter(
                (element) => (
                    element.bankAccountId !== draggedContext.element.bankAccountId
                ),
            );

            disconnectedReq.value = [...disconnectedReq.value, draggedContext.element];

            sentDisconnected.value = [
                ...sentDisconnected.value, draggedContext.element.bankAccountId,
            ];

            disconnect(
                {
                    bankAccountId: draggedContext.element.bankAccountId,
                    merchantId: props.id,
                    type: props.group,
                },
            );
        };

        const moveDisconnected = ({ draggedContext }) => {
            if (!draggedContext.element) {
                return;
            }

            const contains = connectedReq.value
                .map((element) => element.bankAccountId)
                .includes(draggedContext.element.bankAccountId);

            if (contains) {
                return;
            }

            disconnectedReq.value = disconnectedReq.value.filter(
                (element) => (
                    element.bankAccountId !== draggedContext.element.bankAccountId
                ),
            );

            connectedReq.value = [...connectedReq.value, draggedContext.element];

            sentConnected.value = [
                ...sentConnected.value, draggedContext.element.bankAccountId,
            ];

            connect(
                {
                    bankAccountId: draggedContext.element.bankAccountId,
                    merchantId: props.id,
                    type: props.group,
                },
            );
        };

        return {
            cancelModal,
            closeModal,
            connected,
            disconnected,
            isModalOpen,
            moveConnected,
            moveDisconnected,
            openModal,
        };
    },
};
</script>
