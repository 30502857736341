<template>
  <div
    class="columns is-mobile is-multiline"
  >
    <TextInput
      v-model="filters.ipAddress"
      :class="cardDetailsClass"
      label="IP Address"
      name="ip-address"
    />
    <TextInput
      v-model="filters.email"
      :class="cardDetailsClass"
      label="Email"
      name="email"
    />
    <TextInput
      v-if="shouldDisplayCardDetails"
      v-model="filters.cardDetails"
      :class="cardDetailsClass"
      label="Card Details"
      name="card-details"
    />
    <TextInput
      v-if="shouldDisplayBankDetails"
      v-model="filters.bankName"
      :class="bankDetailsClass"
      label="Bank name"
      name="bank-name"
    />

    <TextInput
      v-model="filters.currency"
      :class="bankDetailsClass"
      label="Currency"
      name="currency"
    />
    <StatusList
      v-model:status="filters.status"
      :class="bankDetailsClass"
      label="Status"
      :should-display-bank-details="shouldDisplayBankDetails"
    />

    <TextInput
      v-model="filters.cardId"
      class="column is-full-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Card ID"
      name="card-id"
    />
    <TextInput
      v-model="filters.transactionId"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Transaction ID"
      name="transaction-id"
    />

    <TextInput
      v-model="filters.merchantId"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Merchant ID"
      name="merchant-id"
    />

    <TextInput
      v-if="shouldDisplayMid"
      v-model="filters.mid"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="MID"
      name="mid"
    />

    <TextInput
      v-if="shouldDisplayNonce"
      v-model="filters.nonce"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Nonce"
      name="nonce"
    />

    <TextInput
      v-if="shouldDisplayNames"
      v-model="filters.firstName"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="First Name"
      name="first_name"
    />

    <TextInput
      v-if="shouldDisplayNames"
      v-model="filters.surname"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Surname"
      name="surname"
    />

    <CalendarInput
      v-model:startDate="filters.timestampFrom"
      v-model:endDate="filters.timestampTo"
      :class="calendarClass"
      label="10/10/10"
    />
    <RangeInput
      v-model:amountFrom="filters.amountFrom"
      v-model:amountTo="filters.amountTo"
      class="column is-full pl-1 pr-1 mb-0"
      label="Settled Amount"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import CalendarInput from '../common/CalendarInput.vue';
import RangeInput from '../common/RangeInput.vue';
import TextInput from '../common/TextInput.vue';
import StatusList from './StatusList.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'FilterForm',
    components: {
        CalendarInput,
        RangeInput,
        TextInput,
        StatusList,
    },

    async setup() {
        const { filters } = inject('transactions');

        const flagsmith = await initializeFlagsmith();

        const shouldDisplayCardDetails = flagsmith.hasFeature('should_display_card_details_admin');
        const shouldDisplayBankDetails = flagsmith.hasFeature('check_bank');
        const shouldDisplayMid = flagsmith.hasFeature('admin_mid');
        const shouldDisplayNonce = flagsmith.hasFeature('admin_nonce');
        const shouldDisplayNames = flagsmith.hasFeature('admin_ccg_names');

        return {
            filters,
            shouldDisplayCardDetails,
            shouldDisplayBankDetails,
            shouldDisplayMid,
            shouldDisplayNonce,
            shouldDisplayNames,
        };
    },

    computed: {
        cardDetailsClass() {
            if (this.shouldDisplayCardDetails) {
                return 'column is-full-mobile is-2-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-full-mobile is-one-quarter-tablet pl-1 pr-1 mb-0';
        },

        bankDetailsClass() {
            if (this.shouldDisplayBankDetails) {
                return 'column is-full-mobile is-2-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-half-mobile is-3-tablet pl-1 pr-1 mb-0';
        },

        calendarClass() {
            if (this.shouldDisplayMid) {
                return 'column is-full-mobile is-4-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-full-mobile is-half-tablet pl-1 pr-1 mb-0';
        },
    },
};
</script>
