<template>
  <div>
    <button
      class="button is-small is-primary mb-4"
      title="Add Fingerprint"
      @click="openAddFingerprintModal"
    >
      Add Client ID
    </button>

    <div
      class="modal"
      :class="{'is-active': isAddFingerprintModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">Client ID</label>
              <div class="control">
                <input
                  v-model="newFingerprint.fingerprint"
                  class="input"
                  type="text"
                  placeholder="Enter Client ID"
                >
              </div>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="addIp"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    emits: ['fingerprintAdded'],

    async setup(_props, { emit }) {
        const {
            postFingerprint,
        } = inject('fingerprints');

        const newFingerprint = ref({});
        const isLoading = ref(false);
        const isAddFingerprintModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            newFingerprint.value.fingerprint = '';
            isAddFingerprintModalOpen.value = false;
        };

        const openAddFingerprintModal = () => {
            isAddFingerprintModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = ['fingerprint'];
            return !requiredFields.every((keyName) => (
                newFingerprint.value[keyName]
                && newFingerprint.value[keyName].trim()
            ));
        };

        const addIp = async () => {
            await postFingerprint(newFingerprint.value);
            emit('fingerprintAdded', newFingerprint.value.fingerprint);
            closeModal();
        };

        return {
            addIp,
            closeModal,
            errors,
            isAddFingerprintModalOpen,
            isLoading,
            isSaveButtonActive,
            newFingerprint,
            openAddFingerprintModal,
        };
    },
};
</script>
