import { httpGet, httpPost } from '../http';

export default () => {
    const getIps = async () => {
        const response = await httpGet('blocked-ips/');
        return response;
    };

    const camelToSnake = (data) => ({
        ip_address: data.ipAddress,
    });

    const postIp = async (data) => (
        httpPost('blocked-ips/', {}, camelToSnake(data))
    );

    const deleteIp = async (data) => (
        httpPost('blocked-ips/delete/', {}, camelToSnake(data))
    );

    return {
        getIps,
        postIp,
        deleteIp,
    };
};
