import { httpGet, httpPost, httpPut } from '../http';

export default () => {
    const snakeToCamel = (res) => res.map(({
        bank_id: bankId,
        name,
        code,
        maintanance,
    }) => ({
        bankId,
        name,
        code,
        maintanance,
    }));

    const camelToSnake = (data) => ({
        name: data.name,
        code: data.code,
        maintanance: data.maintanance,
    });

    const fetchBanks = async () => {
        const response = await httpGet(
            'banks/',
        );
        return snakeToCamel(response);
    };

    const postBank = async (data) => {
        const response = await httpPost(
            'banks/',
            {},
            camelToSnake(data),
        );

        return snakeToCamel([response])[0];
    };

    const editBank = async (data) => {
        const response = await httpPut(
            `banks/${data.bankId}`,
            {},
            camelToSnake(data),
        );

        return snakeToCamel([response])[0];
    };

    return {
        editBank,
        fetchBanks,
        postBank,
    };
};
