<template>
  <div>
    <AddBank @bank-added="bankAdded" />

    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>Bank name</th>
          <th>Bank code</th>
          <th>Under maintanance</th>
          <th />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="bank in banks"
          :key="bank.bankId"
        >
          <td>{{ bank.name }}</td>
          <td>{{ bank.code }}</td>
          <td>{{ displayMaintanance(bank.maintanance) }}</td>
          <td>
            <EditBank
              :bank="bank"
              @bank-edited="bankEdited"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import AddBank from './AddBank.vue';
import EditBank from './EditBank.vue';

export default {
    components: {
        AddBank,
        EditBank,
    },

    async setup() {
        const banks = ref([]);

        const {
            fetchBanks,
        } = inject('banks');

        banks.value = await fetchBanks();

        const displayMaintanance = (maintanance) => (maintanance ? 'Yes' : 'No');

        const bankAdded = (addedBank) => {
            banks.value = [...banks.value, addedBank];
        };

        const bankEdited = (editedBank) => {
            const index = banks.value.findIndex((bank) => bank.bankId === editedBank.bankId);

            banks.value[index] = editedBank;
        };

        return {
            bankAdded,
            bankEdited,
            banks,
            displayMaintanance,
        };
    },
};
</script>
