<template>
  <div
    v-if="showNewIpView"
    class="box"
  >
    <FilterForm />
    <TransactionList />
  </div>
  <div
    v-else
    class="columns"
  >
    <div class="column is-3">
      <div class="box">
        <IPBlacklist />
      </div>
    </div>
    <div class="column is-9">
      <div class="box">
        <FilterForm />
        <TransactionList />
      </div>
    </div>
  </div>
</template>

<script>
import FilterForm from '@/FilterForm/Index.vue';
import IPBlacklist from '@/IPBlacklist/Index.vue';
import TransactionList from '@/TransactionList/Index.vue';

import initializeFlagsmith from '../flagsmith';

export default {
    components: {
        IPBlacklist,
        FilterForm,
        TransactionList,
    },

    async setup() {
        const getNewIpViewFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('new_ip_blacklist_view');
        };

        const showNewIpView = await getNewIpViewFlag();

        return {
            showNewIpView,
        };
    },
};
</script>
