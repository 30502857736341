<template>
  <div class="table-container">
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <OrderableTableHeader />
      <tbody v-if="transactions.length > 0">
        <TransactionRow
          v-for="transaction in transactions"
          :key="transaction.transactionId"
          :transaction="transaction"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td
            class="has-text-centered is-size-7"
            colspan="100"
          >
            No transactions found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <DownloadCsvButton :disabled="transactions.length === 0" />
</template>

<script>
import { inject } from 'vue';

import OrderableTableHeader from './OrderableTableHeader.vue';
import TransactionRow from './TransactionRow.vue';
import DownloadCsvButton from './DownloadCsvButton.vue';

export default {
    components: {
        OrderableTableHeader,
        TransactionRow,
        DownloadCsvButton,
    },

    async setup() {
        const {
            transactions: {
                transactions,
                fetchTransactions,
            },
        } = inject('transactions');

        await fetchTransactions();

        return {
            transactions,
        };
    },
};
</script>
