<template>
  <div class="table-container">
    <table
      class="table is-size-6 is-vcentered is-fullwidth blocked-ip-address-list"
      data-cy="locked-ip"
    >
      <thead>
        <tr>
          <th colspan="2">
            Locked IP addresses
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="blockedIpList.length">
          <IPAddress
            v-for="ip in blockedIpList"
            :key="ip"
            :ip="ip"
          />
        </template>
        <template v-else>
          <tr>
            <td
              class="has-text-centered is-size-7"
              colspan="2"
            >
              No IP addresses
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from 'vue';

import IPAddress from './IPAddress.vue';

export default {
    components: {
        IPAddress,
    },

    async setup() {
        const { fetchBlockedIps, blockedIpList } = inject('blocked_ips');
        await fetchBlockedIps();
        return { blockedIpList };
    },
};
</script>
