<template>
  <div class="modal is-active">
    <div
      class="modal-background"
      @click="closeModal"
    />
    <div class="modal-content">
      <div class="box">
        <div class="is-flex is-flex-wrap-wrap columns">
          <div class="field is-half column">
            <label class="label">Nonce</label>
            <div class="control">
              <input
                v-model="editedTransaction.nonce"
                class="input"
                type="text"
                placeholder="Enter nonce"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">E-mail</label>
            <div class="control">
              <input
                v-model="editedTransaction.email"
                class="input"
                type="email"
                placeholder="Enter e-mail"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">First name</label>
            <div class="control">
              <input
                v-model="editedTransaction.firstName"
                class="input"
                type="text"
                placeholder="Enter first name"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Surname</label>
            <div class="control">
              <input
                v-model="editedTransaction.surname"
                class="input"
                type="text"
                placeholder="Enter surname"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Settled amount</label>
            <div class="control">
              <input
                v-if="shouldShowMaskedAmountInputs"
                ref="settledAmountRef"
                class="input"
                type="text"
                placeholder="Enter settled amount"
                @keyup="setAuthorizedAmountWithDebounce"
              >
              <input
                v-else
                v-model="editedTransaction.settledAmount"
                class="input"
                type="number"
                placeholder="Enter settled amount"
                min="0"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Authorized amount</label>
            <div class="control">
              <input
                :value="showLocaleString(editedTransaction.authorizedAmount)"
                class="input"
                type="text"
                readonly
                disabled
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Branch code</label>
            <div class="control">
              <input
                v-model="editedTransaction.branchCode"
                class="input"
                type="text"
                placeholder="Enter branch code"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Bank name</label>
            <div class="control">
              <input
                v-model="editedTransaction.bankName"
                class="input"
                type="text"
                placeholder="Enter bank name"
              >
            </div>
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <p class="control">
            <a
              class="button is-primary"
              @click="updateTransaction"
            >
              Save
            </a>
          </p>
          <p class="control">
            <a
              class="button is-light"
              @click="closeModal"
            >
              Cancel
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import useMaskedMoneyInput from './useMaskedMoneyInput';

import initializeFlagsmith from '../flagsmith';

const CENT_RAW_VALUE_MODIFIER = 100;

export default {
    props: {
        transaction: {
            type: Object,
            required: true,
        },
        merchantId: {
            type: String,
            required: true,
        },
    },

    emits: ['close-modal'],

    async setup(props, { emit }) {
        const editedTransaction = ref({
            ...props.transaction,
        });

        const authorizedAmountRef = ref(props.transaction.authorizedAmount);

        const {
            transactions: {
                patchTransaction,
                updateTransactionValues,
                getAuthorizedAmount,
                createDebounce,
            },
        } = inject('btb_transactions');

        const closeModal = () => {
            emit('close-modal');
        };

        const debounce = createDebounce();

        const setAuthorizedAmountWithDebounce = async () => {
            debounce(async () => {
                await getAuthorizedAmount(
                    editedTransaction.value.settledAmount,
                    props.merchantId,
                ).then((response) => {
                    editedTransaction.value.authorizedAmount = response.authorized_amount;
                    authorizedAmountRef.value = response.authorized_amount;
                });
            });
        };

        const setAuthorizedAmount = async () => {
            await getAuthorizedAmount(
                editedTransaction.value.settledAmount,
                props.merchantId,
            ).then((response) => {
                editedTransaction.value.authorizedAmount = response.authorized_amount;
                authorizedAmountRef.value = response.authorized_amount;
            });
        };

        const updateTransaction = async () => {
            const {
                transactionId,
                surname,
                settledAmount,
                nonce,
                branchCode,
                bankName,
                email,
                firstName,
            } = editedTransaction.value;

            const body = {
                settled_amount: settledAmount,
                surname,
                branch_code: branchCode,
                bank_name: bankName,
                authorized_amount: authorizedAmountRef.value,
                nonce,
                transactionId,
                email,
                first_name: firstName,
            };

            closeModal();

            await setAuthorizedAmount();
            const response = await patchTransaction(transactionId, body);

            if (response instanceof Error) {
                throw response;
            } else {
                const updatedTransaction = editedTransaction.value;
                updatedTransaction.processingFee = response.processing_fee;
                updatedTransaction.creation = response.creation;
                updateTransactionValues(body.transactionId, updatedTransaction);
            }
        };

        const { inputRef: settledAmountRef } = useMaskedMoneyInput({
            initialValue: editedTransaction.value.settledAmount,
            onChange(value) {
                editedTransaction.value.settledAmount = value;
            },
        });

        const flagsmith = await initializeFlagsmith();

        const shouldShowMaskedAmountInputs = flagsmith.hasFeature('btb_masked_amount_inputs');

        const showLocaleString = (value) => (value / CENT_RAW_VALUE_MODIFIER).toLocaleString('en-US');

        return {
            closeModal,
            editedTransaction,
            settledAmountRef,
            shouldShowMaskedAmountInputs,
            updateTransaction,
            showLocaleString,
            getAuthorizedAmount,
            setAuthorizedAmount,
            setAuthorizedAmountWithDebounce,
        };
    },
};
</script>
