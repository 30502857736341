<template>
  <Suspense>
    <template #default>
      <AlternativeIntervals />
    </template>
    <template #fallback>
      Loading...
    </template>
  </Suspense>
</template>

<script>
import AlternativeIntervals from '@/alternativeIntervals/Index.vue';

export default {
    components: {
        AlternativeIntervals,
    },
};
</script>
