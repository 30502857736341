<template>
  <div class="field">
    <div class="control">
      <Datepicker
        v-model="timeObject"
        time-picker
        @cleared="clearValue"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import {
    ref, defineProps, computed, watch,
} from 'vue';

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { DateTime } from 'luxon';

const props = defineProps({
    time: {
        type: String,
        default: '00:00',
    },
    changeTime: {
        type: Function,
        default: null,
    },
});

const setTime = () => ({
    hours: DateTime.fromFormat(props.time, 'HH:mm').toFormat('HH'),
    minutes: DateTime.fromFormat(props.time, 'HH:mm').toFormat('mm'),
});

const timeObject = ref(setTime());

const clearValue = () => {
    timeObject.value = {
        hours: '00',
        minutes: '00',
    };
};

watch(timeObject, () => {
    props.changeTime(DateTime.fromObject(timeObject.value).toFormat('HH:mm'));
});

watch(() => props.time, (time) => {
    timeObject.value = setTime();
});

</script>
