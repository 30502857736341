<template>
  <div v-if="shouldPageShow">
    <div
      v-if="intervalError.trim() !== ''"
      class="notification is-danger"
    >
      {{ intervalError }}
    </div>

    <div
      v-if="intervalSuccess.trim() !== ''"
      class="notification is-success"
    >
      {{ intervalSuccess }}
    </div>

    <table
      class="table is-size-6 is-vcentered is-fullwidth blocked-ip-address-list"
      data-cy="locked-ip"
    >
      <thead>
        <tr>
          <th
            v-for="day in dayOfWeek"
            :key="day.id"
          >
            {{ day.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="day in dayOfWeek"
            :key="day.id"
            class="time"
          >
            <IntervalsTableCell :day="day" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="buttons is-centered">
      <button
        class="button is-small is-primary"
        title="Send values"
        @click="postAlternativeBankTime"
      >
        Send
      </button>
      <button
        class="button is-small is-danger"
        title="Reset"
        @click="resetAlternativeBankTime"
      >
        Reset
      </button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { inject, onMounted, ref } from 'vue';
import IntervalsTableCell from './IntervalsTableCell.vue';
import initializeFlagsmith from '../flagsmith';

const {
    fetchAlternativeBankTime,
    alternativeBankTime,
    postAlternativeBankTime,
    resetAlternativeBankTime,
    intervalError,
    intervalSuccess,
} = inject('alternative_bank');

const shouldPageShow = ref(true);

fetchAlternativeBankTime();

const dayOfWeek = [
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 },
    { name: 'Sunday', id: 7 },
];

</script>
